import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import '@fortawesome/fontawesome-free/css/all.min.css';


import './styles/whatIsHackX.css';
import './styles/TimeLine.css'
import './styles/Process.css'
import './styles/OurTeam.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);


