import React from 'react';
import { SocialIcon } from 'react-social-icons';

const Sidebar = () => {
  const iconStyle = { margin: '10px' };

  return (
    <div className="sidebar">
      <SocialIcon url="https://facebook.com" style={iconStyle} />
      <SocialIcon url="https://linkedin.com" style={iconStyle} />
      <SocialIcon url="https://www.instagram.com/hackx_uok?igsh=MWJudzl0c3NjOGJ2ag==" style={iconStyle} />
      <SocialIcon url="https://youtube.com" style={iconStyle} />
      {/* Add more SocialIcon components with different URLs as needed */}
    </div>
  );
};

export default Sidebar;
