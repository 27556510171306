import React from 'react';
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import carouselImage1 from '../images/homeGallery.jpg'; // Adjust the path as needed
import carouselImage2 from '../images/homeGallery2.jpg'; // Adjust the path as needed
import carouselImage3 from '../images/homeGallery3.jpg'; // Adjust the path as needed
import './App.css'; // Import the CSS file

export default function App() {
  return (
    <MDBCarousel showControls interval={3000}>
      <MDBCarouselItem itemId={1} interval={1000}>
        <img src={carouselImage1} className='d-block w-100 carousel-image' alt='First slide' />
        <div className='carousel-caption d-none d-md-block'>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2}>
        <img src={carouselImage2} className='d-block w-100 carousel-image' alt='Second slide' />
        <div className='carousel-caption d-none d-md-block'>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={3}>
        <img src={carouselImage3} className='d-block w-100 carousel-image' alt='Third slide' />
        <div className='carousel-caption d-none d-md-block'>
          {/* <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
        </div>
      </MDBCarouselItem>
    </MDBCarousel>
  );
}
