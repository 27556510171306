import React from 'react';
import Card from 'react-bootstrap/Card';
import Image1 from '../images/medal.png';
import Image2 from '../images/medal.png';
import Image3 from '../images/medal.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function DarkVariantExample() {

    return (
        <center>
            <div className="awrds-section" style={{ padding: '20px', color: '#fff' }}>
                <div className="row">
                    <div className="col-md-4" id="one">
                        <Card className="custom-card" style={{ width: '18rem', borderRadius: '10px', border: '1px solid #ccc', position: 'relative' }}>
                            <Card.Img variant="top" src={Image1} className="card-img-no-border circular-image" />
                            <Card.Body style={{ backgroundColor: 'transparent' }}>
                                <Card.Title><h2><center>1<sup>st</sup> Runner Up</center></h2></Card.Title><br></br>
                                <Card.Text>
                                    <h5 style={{ color: '#F1B61E', textAlign: 'center' }}>LKR</h5><br></br>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-4 " id="champ">
                        <Card className="custom-card" style={{ width: '18rem', borderRadius: '10px', border: '1px solid #F1B61E', position: 'relative' }}>
                            <Card.Img variant="top" src={Image2} className="card-img-no-border circular-image" />
                            <Card.Body style={{ backgroundColor: 'transparent' }}>
                                <Card.Title><h2><center>Champion</center></h2></Card.Title><br></br>
                                <Card.Text>
                                    <h5 style={{ color: '#F1B61E', textAlign: 'center' }}>LKR</h5><br></br>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-4" id="two">
                        <Card className="custom-card" style={{ width: '18rem', borderRadius: '10px', border: '1px solid #ccc', position: 'relative' }}>
                            <Card.Img variant="top" src={Image3} className="card-img-no-border circular-image" />
                            <Card.Body style={{ backgroundColor: 'transparent' }}>
                                <Card.Title><h2><center>2<sup>nd</sup> Runner Up</center></h2></Card.Title><br></br>
                                <Card.Text>
                                    <h5 style={{ color: '#F1B61E', textAlign: 'center' }}>LKR</h5><br></br>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </center>
    );
}

export default DarkVariantExample;
