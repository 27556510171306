import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/logo.png";
import "./App.css";

const ColorSchemesExample = ({
  homeRef,
  aboutRef,
  timelineRef,
  processRef,
  awardRef,
  galleryRef,
  faqsRef,
  contactRef,
}) => {


  const scrollToSection = (ref) => {
    console.log("Ref", ref == null, ref);
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Reference is null or undefined. Cannot scroll to section.");
    }
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        fixed="top"
        style={{
          background: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(10px)',
              
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
              border: 'none',
              color: 'white'
        }}
      >
        <Container className="px-0">
        <div className="logo-container">
  <Navbar.Brand onClick={() => scrollToSection(contactRef)}>
    <img
      src={logo}
      alt="Logo"
      style={{
        height: "54px",
        width: "100px",
      }}
    />
  </Navbar.Brand>
</div>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => scrollToSection(homeRef)}>HOME</Nav.Link>
              <Nav.Link onClick={() => scrollToSection(aboutRef)}>
                ABOUT
              </Nav.Link>
              <Nav.Link onClick={() => scrollToSection(timelineRef)}>
                TIMELINE
              </Nav.Link>
              <Nav.Link onClick={() => scrollToSection(processRef)}>
                PROCESS
              </Nav.Link>
              <Nav.Link onClick={() => scrollToSection(awardRef)}>
                AWARDS
              </Nav.Link>
              <Nav.Link onClick={() => scrollToSection(contactRef)}>
                TEAM
              </Nav.Link>
              <Nav.Link onClick={() => scrollToSection(galleryRef)}>
                GALLERY
              </Nav.Link>
              <Nav.Link onClick={() => scrollToSection(faqsRef)}>FAQs</Nav.Link>
              <Nav.Link onClick={() => scrollToSection(contactRef)}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default ColorSchemesExample;
