import React from "react";
import { MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import regIcon from "../images/registration-form.png";
import awarenessIcon from "../images/awarness.png"
import submissionIcon from "../images/submission.png"
import deadlineIcon from "../images/deadline.png"
import workshop1Icon from "../images/onlineWorkshopSeries1.png"
import workshop2Icon from "../images/onlineWorkshopSeries2.png"
import semiFinals from "../images/semiFinals.png"
import trophyIcon from "../images/trophy.png"
import './App.css';

export default function App() {
  return (
    <MDBContainer fluid className="py-5">
      <div className="main-timeline">
        <div className="timeline left">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={regIcon} alt="Registration Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Registration Period</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard className="card-width" style={{ backgroundColor: '#232D3F', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={awarenessIcon} alt="Awareness Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Awareness Session</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline left">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={submissionIcon} alt="Submissions Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Proposal Submissions Open</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard className="card-width" style={{ backgroundColor: '#232D3F', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={deadlineIcon} alt="Deadline Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Deadline for Proposal Submissions</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline left">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={workshop1Icon} alt="Online Workshop Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Online Workshop Series 1</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard className="card-width" style={{ backgroundColor: '#232D3F', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={semiFinals} alt="semiFinals Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Semi-Finals</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline left">
          <MDBCard className="card-width" style={{ backgroundColor: 'black', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={workshop2Icon} alt="Online Workshop Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Online Workshop Series 2</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard className="card-width" style={{ backgroundColor: '#232D3F', color: 'white' }}>
            <MDBCardBody className="p-4">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={trophyIcon} alt="Finals Icon" width="50" height="50" style={{ marginRight: '10px' }} />
                <h3>Grand Finals of hackX 8.0</h3>
              </div>
              <br></br>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
                admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
                quis iuvaret expetendis his, te elit voluptua dignissim per,
                habeo iusto primis ea eam.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </MDBContainer>
  );
}
