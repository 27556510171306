import React from "react";
import Intro from "../components/whatIsHackX";
import Header from "../components/Header"

const WhatIsHackXPage = ({ pageRef }) => {
  const pageStyle = {
    paddingTop: '80px',
    minHeight: '100vh', // Make sure the page covers the full viewport height
  };

  return (
    <div ref={pageRef} style={pageStyle}>
      <Header />
      <Intro />
      <br></br>
    </div>
  );
};

export default WhatIsHackXPage;