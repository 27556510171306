import React from 'react';

const Header = () => {
    const headerStyle = {
        textAlign: 'center',
        color: 'white',
        paddingTop: '50px'
    };

    const textStyle = {
        textAlign: 'justify',
        color: 'white',
        marginLeft: '80px',
        marginRight: '80px',
        fontSize: '18px'
    };

    return (
        <div style={headerStyle}>
            <h1>What is hackX Jr.?</h1>
            <p style={textStyle}>
                The first-ever inter-school hackathon, proudly presented by the Industrial Management Science Students’ Association (IMSSA) of the Department of Industrial Management, University of Kelaniya. hackX Jr. unveils its 7th exhilarating iteration, centered around the concept of “Give Shapes to Ideas.”
                <br /><br />
                We aim to provide a dynamic platform where the brightest schoolchildren’s minds can turn their ideas into reality. hackX Jr. goes beyond the classroom by directly connecting students with industry experts. Through mentorship and guidance, students gain invaluable insights and expertise, accelerating their journey toward excellence.
            </p>
        </div>
    );
};

export default Header;
