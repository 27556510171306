import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import SocialSideBar from './SocialSideBar';
import uniLogo from '../images/uniLogo.png';
import './App.css';
 // Update the path to your logo1 image

export default function App() {
  return (
    <MDBFooter
      style={{
        backgroundColor: 'rgba(46, 46, 46, 0.5)',
        backdropFilter: 'blur(10px)',
        color: 'white',
        fontSize: '18px',
      }}
      className='text-center text-lg-start text-muted'
    >
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block' style={{ color: 'white', fontSize: '18px' }}>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <SocialSideBar />
        </div>
      </section>

      <section>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='6' className='mx-auto mb-4'>
              <p style={{ color: 'white', fontSize: '18px', marginBottom: '0' }}>
                Industrial Management Science Students’ Association<br />
                Department of Industrial Management,<br />
                University of Kelaniya, Sri Lanka
              </p>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }} className="lg-div">
                  <div style={{ marginRight: '25px' }}>
                    <img src="uok-min.webp" alt="Logo 1" style={{ height: '60px' }} />
                  </div>
                  <div style={{ marginRight: '10px' }}>
                    <img src="IMSSA-min.webp" alt="Logo 1" style={{ height: '60px' }} />
                  </div>
                  <div style={{ marginleft: '-5px' }}>
                    <img src="MIT-min.webp" alt="Logo 1" style={{ height: '120px' }} />
                  </div>
                </div>

            </MDBCol>

            <MDBCol md='6' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: 'white', fontSize: '18px', marginBottom: '20px' }}>Contact</h6>
              {/* Rest of your contact information */}
              <p style={{ color: 'white', fontSize: '18px' }}>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                hackx.mit@gmail.com
              </p>
              <p style={{ color: 'white', fontSize: '18px' }}>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> +94 75 406 1973
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', color: 'rgba(169, 169, 169, 0.8)', fontSize: '14px' }}
>
        Copyright © hackX 9.0 2024 - All Rights Reserved.
      </div>
    </MDBFooter>
  );
}
