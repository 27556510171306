import React from 'react';
import { Card, Button } from 'react-bootstrap';

const Process = () => {
  return (
    <center>
      <Card className="custom-card mx-3" style={{
        background: "rgba(255, 255, 255, 0.2)",
        borderRadius: "18px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(1.6px)",
        WebkitBackdropFilter: "blur(1.6px)",
        border: "2px solid #00BFFF",
      }}>
        <Card.Body>
          <ul className="custom-list">
            <li className="yellow-bullet">All participants should be undergraduates of a University or a Higher Education Institute.</li><br></br>
            <li className="yellow-bullet">A team should consist of 3 to 5 members representing the same University or Institute.</li><br></br>
            <li className="yellow-bullet">One contestant can represent only one team.</li><br></br>
            <li className="yellow-bullet">The ideas should be original and innovative.</li><br></br>
            <li className="yellow-bullet">Ideas must be presented in a proposal that complies with the proposal format and guidelines outlined.</li><br></br>
            <li className="yellow-bullet">Furthermore, the final product might include more features, but the proposed idea cannot be amended after it has been submitted.</li><br></br>
          </ul>
          <center>
            <Button className="custom-button">All Rules And Regulations</Button>
          </center>
        </Card.Body>
      </Card>
    </center>
  );
};

export default Process;
